var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardWrapper',{staticClass:"space-b-6",attrs:{"solo-footer":false},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ButtonTextIcon',{attrs:{"color":"primary","icon":"$trash"},on:{"click":function($event){return _vm.$emit('delete-promotion')}}},[_vm._v("Удалить промоакцию")]),_c('VBtn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('submit-promotion')}}},[_vm._v("Сохранить")])]},proxy:true}])},[_c('VCardText',[_c('div',{staticClass:"row-header"},[_c('div',{staticClass:"text-h3-2"},[_vm._v("Коды")]),_c('ButtonTextIcon',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.isShowAddPromocodes = true}}},[_vm._v("Добавить")])],1)]),_c('AddPromocodesModal',{attrs:{"visible":_vm.isShowAddPromocodes,"baseCode":_vm.form.code},on:{"update:visible":function($event){_vm.isShowAddPromocodes=$event},"close":function($event){_vm.isShowAddPromocodes = false},"codes":_vm.handleAddCode}}),_c('VDataTable',{staticClass:"rounded-top-off",attrs:{"headers":_vm.headers,"items":_vm.form.codes,"items-per-page":-1,"hide-default-footer":"","disable-sort":true},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.code))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"teal--text text--darken-4"},[_vm._v(_vm._s(_vm.getCodeType(item.type)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"code-users"},_vm._l((_vm.codePeople(item)),function(userData,index){return _c('UserSocialCard',{key:index,attrs:{"user":userData}})}),1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('Tag',{attrs:{"color":item.status ? 'grey' : 'blue',"type":"bright"}},[_vm._v(_vm._s(item.status ? 'активирован' : 'не активирован'))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteCode(item.code)}}},[_c('VIcon',[_vm._v("$close")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }