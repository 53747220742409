



























































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// utils
import { tableFooterOptions } from '@/utils/constants'
// components
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerCoursesModule from '@/store/modules/manager/courses'
// interfaces
import { CourseShortResource, CourseShortResourcePaginator, IManagerCoursesFilter, IManagerCoursesFilterType } from '@/store/types'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class SearchCourseModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: false })
  private isRecommendation!: boolean

  @Prop({ default: () => ([]) })
  private recommendationCourses!: CourseShortResource[]

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private courses: CourseShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  private selected: CourseShortResource[] = []

  private filter: IManagerCoursesFilter = {
    ...GET_DEFAULT_TABLE_FILTER(),
    itemsPerPage: 10,
    marketableOnly: this.isRecommendation ? true : undefined,
  }

  private handleFilter (field: IManagerCoursesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private getMonthsRange (courseStart: string, courseEnd?: string) {
    if (courseEnd) {
      return `${formatDate(courseStart, 'LLLL')} - ${formatDate(courseEnd, 'LLLL')}`
    }
    return formatDate(courseStart, 'LLLL')
  }

  private footerOptions = tableFooterOptions

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get types () {
    return DictionaryModule.courseTypes
  }

  private get headers () {
    return [
      { cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { sortable: false, text: 'Курс', value: 'title' },
      { sortable: false, text: 'Тип', value: 'type' },
      { sortable: false, text: 'Предметы', value: 'subject' },
      { sortable: false, text: 'Дата проведения', value: 'date' },
    ]
  }

  @Bind
  @Debounce(300)
  private searchCourses () {
    ManagerCoursesModule.searchCourses(this.filter)
      .then((response: CourseShortResourcePaginator) => {
        this.courses = Object.assign({}, response)
      })
      .catch(this.notifyError)
  }


  private handleClose() {
    this.$emit('update:visible', false)
  }

  private handleSelect () {
    this.$emit('select', this.selected)
    this.selected = []
    this.handleClose()
  }

  @Watch('filter', { deep: true })
  watchFilter() {
    this.searchCourses()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value && this.isRecommendation) {
      this.selected = [...this.recommendationCourses]
    }
  }
}
