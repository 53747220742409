












import { Bind, Debounce } from 'lodash-decorators'
import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import PromoBaseForm from '@/components/forms/promo/PromoBaseForm.vue'
// store
import ManagerPromotionModule from '@/store/modules/manager/offer'
// interfaces
import { PromotionData, PromotionLargeResource } from '@/store/types'

@Component({
  components: {
    PromoBaseForm,
  },
})
export default class OfferNew extends NotifyMixin {
  private get breadcrumbs () {
    return [{ name: 'Промоакции', path: '/manager/commerce/promocodes' }]
  }

  @Debounce(500)
  @Bind
  private handleSubmit (form: PromotionData) {
    ManagerPromotionModule.savePromotion({ params: form })
      .then((response: PromotionLargeResource) => {
        this.notifySuccess('Промоакция создана')
        this.$router.push({ name: 'manager.commerce.promocodes.edit', params: { promoId: response.id.toString() } })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание промоакции',
    }
  }
}
