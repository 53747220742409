var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('ModalWrapper',{attrs:{"title":"Создание кодов"},on:{"close":_vm.handleModalClose,"cancel":_vm.handleModalClose,"confirm":_vm.handleGenerateCodes}},[_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
return [_c('span',{staticClass:"text-body-1"},[_vm._v("Вы можете задать текст промокода вручную или автоматически сгенерировать уникальные")]),_c('ValidationProvider',{staticClass:"my-8",attrs:{"name":"promocodeTypes","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"name":"promocodeTypes","options":_vm.promocodeTypes,"dense":"","outlined":"","rounded":"","placeholder":"Выберите промокод","label":"Тип промокода","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.promocode.type),callback:function ($$v) {_vm.$set(_vm.promocode, "type", $$v)},expression:"promocode.type"}})]}}],null,true)}),_c('div',{staticClass:"teal--text text--darken-4 text-body-2"},[_vm._v("Создание промокода")]),_c('div',{staticClass:"promocode-modal__create-method"},[_c('div',{staticClass:"text-body-1",class:{ 'grey--text': _vm.promocode.method === 'auto' },on:{"click":function($event){_vm.promocode.method = 'manual'}}},[_vm._v("Ручное")]),_c('div',{staticClass:"field"},[_c('VSwitch',{attrs:{"false-value":"manual","true-value":"auto","inset":""},model:{value:(_vm.promocode.method),callback:function ($$v) {_vm.$set(_vm.promocode, "method", $$v)},expression:"promocode.method"}})],1),_c('div',{staticClass:"text-body-1",class:{ 'grey--text': _vm.promocode.method !== 'auto' },on:{"click":function($event){_vm.promocode.method = 'auto'}}},[_vm._v("Автоматическое")])]),_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"baseCode","rules":"required|alpha_dash","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"promocode-modal__row"},[_c('span',{staticClass:"text-body-1"},[_vm._v("Основа кода")]),_c('TextInput',{attrs:{"dense":"","outlined":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.promocode.baseCode),callback:function ($$v) {_vm.$set(_vm.promocode, "baseCode", $$v)},expression:"promocode.baseCode"}})],1)]}}],null,true)}),(_vm.promocode.method === 'auto')?_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"promocodesAmount","rules":"alpha_num|required||min_value:1|max_value:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"promocode-modal__row mb-0"},[_c('span',{staticClass:"text-body-1"},[_vm._v("Количество кодов")]),_c('TextInput',{attrs:{"type":"number","min":"1","dense":"","outlined":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.promocode.amount),callback:function ($$v) {_vm.$set(_vm.promocode, "amount", $$v)},expression:"promocode.amount"}})],1)]}}],null,true)}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }