





















































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// libraries
import randomstring from 'randomstring'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import DictionaryModule from '@/store/modules/dictionary'
// interfaces
import { PromoCodeType, PromotionCodeData } from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    Select,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddPromocodesModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private baseCode!: string

  private get promocodeTypes() {
    return DictionaryModule.promotionCodeTypes
  }

  private promocode: { amount: number, baseCode: string, method: 'auto' | 'manual', type: PromoCodeType } = {
    amount: 0,
    baseCode: '',
    method: 'auto',
    type: PromoCodeType.DISPOSABLE,
  }

  private handleModalClose() {
    this.$emit('close')
  }

  private _generateCode (baseCode: string): PromotionCodeData {
    return {
      code: `${baseCode.toUpperCase()}-${randomstring.generate(5)}`,
      type: this.promocode.type,
    }
  }

  private handleGenerateCodes() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const codes: PromotionCodeData[] = []

          if (this.promocode.method === 'manual') {
            codes.push({
              code: this.promocode.baseCode.toUpperCase(),
              type: this.promocode.type,
            })
          }

          if (this.promocode.method === 'auto') {
            for (let i = 0; i < this.promocode.amount; i++) {
              codes.push(this._generateCode(this.promocode.baseCode))
            }
          }
          requestAnimationFrame(() => {
            form.reset()
            this.promocode = {
              amount: 0,
              baseCode: '',
              method: 'auto',
              type: PromoCodeType.DISPOSABLE,
            }
          })
          this.$emit('codes', codes)
          this.$emit('close')
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

}
