import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IPromoFilter,
  ManagerPromotionsGetRequest,
  ManagerPromotionsPostRequest,
  ManagerPromotionsPromotionIdDeleteRequest,
  ManagerPromotionsPromotionIdGetRequest,
  ManagerPromotionsPromotionIdPatchRequest,
  PromotionCodeResource,
  PromotionData,
  PromotionLargeResource,
  PromotionShortResourcePaginator,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'ManagerPromo',
  namespaced: true,
  store,
})
class ManagerPromo extends VuexModule {
  // ---------------------------- Promo ---------------------------- >>
  promoFilter: IPromoFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setPromoFilter (payload: IPromoFilter) {
    this.promoFilter = Object.assign({}, payload)
  }

  // ---------------------------- Promo It self ---------------------------- >>
  // promotions: PromotionCollectionResource[] = []

  promotions: PromotionShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setPromotions(payload: PromotionShortResourcePaginator) {
    this.promotions = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchPromotions (): Promise<PromotionShortResourcePaginator> {
    const { data } = await ManagerPromotionsGetRequest(tableOptionsToParams(this.promoFilter))
    this.setPromotions(data)
    return data
  }

  // ---------------------------- Promotions ---------------------------- >>
  promotion: PromotionLargeResource | null = null

  promotionBreif: PromotionData | null = null

  promotionCodes: PromotionCodeResource[] = []

  @Mutation
  setPromotion(payload: PromotionLargeResource) {
    this.promotion = payload
  }

  @Mutation
  unsetPromotion() {
    this.promotion = null
  }

  @Action({ rawError: true })
  async fetchPromotion(promotionId: number): Promise<PromotionLargeResource> {
    const { data } = await ManagerPromotionsPromotionIdGetRequest(promotionId)
    this.setPromotion(data)
    return data
  }

  @Action({ rawError: true })
  async savePromotion(payload: {promotionId?: number, params: PromotionData}): Promise<PromotionLargeResource> {
    if (payload.promotionId) {
      const { data } = await ManagerPromotionsPromotionIdPatchRequest(payload.promotionId, payload.params)
      this.setPromotion(data)
      return data
    } else {
      const { data } = await ManagerPromotionsPostRequest(payload.params)
      this.setPromotion(data)
      return data
    }
  }

  @Action({ rawError: true })
  async deletePromotion(promotionId: number): Promise<void> {
    const { data } = await ManagerPromotionsPromotionIdDeleteRequest(promotionId)
    this.unsetPromotion()
    return data
  }
}

const ManagerPromotionModule = getModule(ManagerPromo)

export default ManagerPromotionModule
